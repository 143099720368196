
import classes from "./ProjectCard.module.scss"
export default function ProjectCard({Picture,Name,Description,Link}) {
    return (

  <div className={classes["project-card"]}>


    <div  className={classes["picture"]}>{Picture}</div>
    <div  className={classes["picture"]}>{Name}</div>
    <div  className={classes["picture"]}>{Description}</div>
    <div  className={classes["picture"]}>{Link}</div>

    </div>
    );
  }
  
import classes from './ExperienceCard.module.scss'
export default function ExperienceCard({posName,year,description}) {
    return (
        <>
  <div className={classes["position-name"]} >{posName}</div>
       <div className={classes["year"]}>
        {year}
       </div>
        <div className={classes["description"]}>
        {description}
        </div>
        </>
    );
  }
  

import React from "react";
import classes from "./Home.module.scss"
export default function Home() {
    return (
  <div className={classes["main-container"]}>
    <div className={classes["left-container"]}>
      <div>My name is</div><div>Muhammad Abdullah Riaz</div>
      Data Analyst & Scientist adept at translating intricate data into actionable insights. Proficient in analytics,
       visualizations, and leveraging machine learning for informed decision-making. Skilled in revealing patterns, 
      empowering strategic business enhancements through data-driven approaches.
    <div>
      LinkedIn Github Email Resume
    </div>
    </div>
    <div className={classes["right-container"]}>
        Picturec csaaaaaaaassssssssssssssssssssss
    </div>

  </div>
  
    );
  }
  

import ExperienceCard from "../../components/experience-card/ExperienceCard";
import classes from "./career.module.scss"
export default function Career() {
const careerData=[{posName: "Full Stack Data Scientist",year:"2022 - 2024",
  description:"Murray Aronson: Built dynamic Next.js site with real-time data analysis and API integration. Optimized performance & UX via Google Analytics, leading to faster loading times. InstaCart: Analyzed customer behavior (clicks, sessions, etc.) using Firebase & Python. Visualized insights to improve user journeys & engagement through targeted campaigns and website optimization. Virtual Escape: Led full-stack development with React UI, MongoDB backend, JWT auth & private routing. Honed collaborative coding & version control skills, showcasing full-stack expertise & security focus."


},
  {
    posName: "Data Analyst",
    year: "2020 – 2022",
    description: "Automatic Reed Pick Glass: Developed mobile app using OpenCV & image analysis for automated fabric data extraction (warp/weft, color, dimensions) in quality control using smart phone camera.Quarterly Sales Report: Developed an interactive Power BI dashboard to visualize and analyze quarterly sales data, demonstrating strong proficiency in Power BI for enhancing real-time performance tracking and strategic decision-making.Covid Vaccination Analysis: Developed a Power BI dashboard for analyzing global Covid-19 vaccination data (WHO), showcasing expertise in data integration and visualization to track vaccination progress effectively."
  }
]

    return (
  <div className={classes["main-container"]}>
   <div className={classes["header"]}>Career</div>
   <div>
   {careerData.map((data, index) => (
          <ExperienceCard key={index} {...data} />
        ))}
   </div>
  </div>
    );
  }
  
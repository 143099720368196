import ProjectCard from "../../components/project-card/ProjectCard";
import classes from "./Projects.module.scss"
export default function Project() {
    return (
      <div>
<div className={classes["project-header"]}>
  Projects
</div>
  <div className={classes["main-container"]}>
 
    <ProjectCard Name={'Name'} Description={"Description"} Link={"link"} Picture={"Picture"}/>
    <ProjectCard Name={'Name'} Description={"Description"} Link={"link"} Picture={"Picture"}/>


    <ProjectCard Name={'Name'} Description={"Description"} Link={"link"} Picture={"Picture"}/>
    <ProjectCard Name={'Name'} Description={"Description"} Link={"link"} Picture={"Picture"}/>
 
   

    </div>
      </div>
    );
  }
  
import NavigationBar from "./components/navigation-bar/NavigationBar";
import Career from "./routes/career/career";
import Home from "./routes/home/Home";
import Project from "./routes/projetcs/Project";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
       
        <Route path="*" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/project" element={<Project />} />
      </Routes>
    </Router>
  );
}

export default App;

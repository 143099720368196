"use client";
import { useState,useEffect } from "react";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import classes from "./NavigationBar.module.scss"
import { useNavigate} from "react-router-dom";
const NavigationBar = () => {
  const [isDrawerEnable, setisDrawerEnable] = useState(false);
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const { width } = useWindowDimensions();
  const navigate  = useNavigate();
  const toggleClass = () => {
    setisDrawerOpen(!isDrawerOpen);
  };
  const navigateTo = (path) => {
    navigate(path);
    setisDrawerOpen(false);
  };
  useEffect(() => {
    if (width && width <= 768) {
      setisDrawerEnable(true);
    } else {
      setisDrawerEnable(false);
    }
  }, [width]);


  return (<>
    <div className={classes["navigation-bar"]}>
<div className={classes["navigation-bar-container"]}>
  <div className={classes["clickable"]} onClick={() => navigateTo('/')}>LogiSurge</div>
    {!isDrawerEnable && (<div className={classes["pages-container"]}>
      <div className={classes['clickable']} onClick={() => navigateTo('/career')}>Career</div>
      <div className={classes['clickable']} onClick={() => navigateTo('/project')} >Projects</div>
      <div className={classes['clickable']}>Contact</div>
    </div>)}
    {isDrawerEnable && (
        <div className={classes["drawer-container"]} onClick={toggleClass}>
          ☰
          
        </div>
      )}
    </div>  
    {isDrawerEnable && isDrawerOpen && (
    <div className={classes["drawer-pages-container"]}>
          <div onClick={() => navigateTo('/career')} >Career</div>
          <div onClick={() => navigateTo('/project')}>Projects</div>
          <div >Contact</div>
          
        </div>
        )}
</div>
{isDrawerEnable && isDrawerOpen && (<div className={classes["control-opacity"]} onClick={toggleClass}></div>)}
          </>
  );
};

export default NavigationBar;
